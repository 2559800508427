<template>
  <div class="about">
    <div class="about__header">
      <img
        class="about__header_background"
        src="@/assets/images/about/about_background.png"
        alt="About Image"
      />
    </div>

    <section class="about__story">
      <h2 class="about__story_title title">Our Story</h2>
      <span class="about__story_text text"
        >For over 30 years, browngrotta arts has advanced the field of contemporary art by
        exhibiting renowned artists from the US and abroad. The gallery showcases art textiles and
        unique sculptural, ceramic, and mixed media works with an emphasis on concept, supported by
        technique.</span
      >
      <img
        class="about__story_image section-image"
        src="@/assets/images/about/our_story_image.png"
        alt="Tom Grotta and Rhonda Brown"
      />
      <span class="about__story_text text">
        Representing many of the artists who have helped define modern fiber art since the 1950s,
        browngrotta arts reflects the aesthetic and advocacy of its co-curators, husband-and-wife
        team, Tom Grotta and Rhonda Brown. Museum-quality artworks by more than 100 artists from 25
        countries are represented through gallery exhibitions, art fairs, co-partnerships with
        museums and retail spaces, and an online gallery. browngrotta arts has more than published
        50 art catalogs, featuring Tom’s photographs and design and Rhonda’s editorial oversight.
        The firm has placed works in private and corporate collections in the US and abroad,
        including the permanent collections of the Metropolitan Museum of Art, Museum of Arts and
        Design, the Art Institute of Chicago, Philadelphia Museum of Art, and the Renwick Gallery of
        the Smithsonian American Art Museum. The curators regularly work with architects and
        interior designers offering consultation for commissioned artworks and site-specific
        installation for commercial and residential spaces.</span
      >
    </section>

    <section class="about__mission">
      <h2 class="about__mission_title title">Our Mission</h2>
      <span class="about__mission_text text"
        >The mission of browngrotta arts is threefold: 1) to share with international audiences art
        textiles, fiber sculpture, craft, and mixed media from established and emerging artists from
        the United States and abroad; 2) to educate people about the origins of the fiber art
        movement and its expanded current state through our exhibitions, publication program, and
        curatorial partnerships; and 3) to excite people about the prospect of acquiring dimensional
        art and integrating the work we represent into their homes, workplaces and museum
        collections.
      </span>
      <img
        class="about__mission_image section-image"
        src="@/assets/images/about/our_mission_image.png"
        alt=""
      />
    </section>

    <section class="about__about-us">
      <h2 class="about__about-us_title title">About Us</h2>
      <span class="about__about-us_text text"
        >Tom Grotta graduated from RIT with a fine arts degree in photography. He has been
        recognized for his extensive knowledge of contemporary art textiles and fiber sculpture and
        for his photography of fiber art. He has lectured on these subjects at museums and art
        programs in New York, California, Hawaii and elsewhere.Tom's art photographs are included in
        several private collections and that of the Serralves Museum in Portugal. His photographs
        have been included in museum installations throughout the US including Ethel Stein: Master
        Weaver at the Art Institute of Chicago, Illinois; In the Realm of Nature: Bob Stocksdale &
        Kay Sekimachi at the Mingei International Museum, California, Works by Toshiko Takaezu at
        the Hunterdon Museum, New Jersey, and This Present Moment: Carfting a Better World at the
        Renwick Gallery, Smithsonian American Art Museum. His photos of artists and their works have
        been published in numerous books including California’s Designing Women 1896-1986; Fiber:
        Sculpture 1960 - Present; Makers: A History of American Studio Craft; and Tapestry: A Woven
        Narrative and international magazines and newspapers including The New York Times,
        Architectural Digest, selvedge and HandEye. Tom also designed and photographed The Grotta
        Home by Richard Meier: a Marriage of Architecture and Craft and Gyöngy Laky: Screwing With
        Order, both published by arnoldsche art publishers. Rhonda Brown is a lawyer and human
        resources consultant who worked as a writer and editor in a previous career. She is the
        co-author of Making Room: Strategies for Small Spaces. Her writing on art has appeared in
        selvedge, Surface Design Journal, Fiber Arts Now, Weston magazine and in browngrotta arts’
        catalogs and blog, arttextstyle. She manages editorial for browngrotta arts’ catalogs,
        website, and social media presence.</span
      >
      <div class="carousel-wrapper">
        <IconButton
          class="carousel__button carousel__button--back button--circle button--white"
          @click="goToSlide('prev')"
        >
          <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
        </IconButton>
        <VueSlickCarousel v-bind="carouselSettings" ref="carousel" @afterChange="changeSlide">
          <div v-for="(slide, index) in carouselData" :key="index" class="slide">
            <img class="image" :src="slide.image" :alt="slide.title" />
          </div>
        </VueSlickCarousel>
        <IconButton
          class="carousel__button carousel__button--next button--circle button--white"
          @click="goToSlide('next')"
        >
          <IconArrowTop class="carousel__button-icon carousel__button-icon"></IconArrowTop>
        </IconButton>
      </div>
      <p class="about__about-us__slide-description text-description">{{ slideActive.title }}</p>
    </section>

    <!-- <section class="about__exhibitions" v-if="featuredExhibition.slug != null">
      <h2 class="about__exhibitions_title title">Exhibitions & Events</h2>
      <span class="about__exhibitions_text text">
        {{ featuredExhibition.description }}
      </span>
      <BaseButton
        class="button button--xs button--white"
        text="VIEW EXHIBITIONS & EVENTS"
        @click="$router.push({ path: `/exhibitions/${featuredExhibition.slug}` })"
      />
    </section>

    <span class="text mobile">To contact browngrotta arts, please contact Tom Grotta:</span>

    <Contacts class="about__contacts" />
    <SignUpForUpdates class="about__sign-up" /> -->
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
//import NotificationMarker from "@/components/NotificationMarker.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
//import Contacts from "@/components/Contacts.vue";
//import SignUpForUpdates from "@/components/SignUpForUpdates.vue";

export default {
  components: {
    BaseButton,
    //NotificationMarker,
    IconButton,
    IconArrowTop,
    VueSlickCarousel,
    //Contacts,
    //SignUpForUpdates,
  },

  data() {
    return {
      carouselSettings: {
        centerMode: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        dots: false,
        infinite: true,
        centerPadding: "20px",
        variableWidth: true,
        responsive: [
          {
            breakpoint: 540,
            settings: {
              centerMode: false,
            },
          },
        ],
      },
      carouselData: [
        {
          image: require("@/assets/images/about/carousel/AnnetteBellamy-GretheSørensen.jpg"),
          title: "works by Annette Bellamy and Grethe Sørensen",
        },
        {
          image: require("@/assets/images/about/carousel/ÅseLjones-JohnMcQueen-MarianBijlenga-EthelStein-WendyWahl.jpg"),
          title: "works by ÅseL jones, John McQueen, Marian Bijlenga, Ethel Stein and Wendy Wahl",
        },
        {
          image: require("@/assets/images/about/carousel/CarolineBartlett-PollySutton-FerneJacobs.jpg"),
          title: "works by Caroline Bartlett, Polly Adams Sutton and Ferne Jacobs",
        },
        {
          image: require("@/assets/images/about/carousel/ChiyokoTanaka-Jin-SookSo.jpg"),
          title: "works by Chiyoko Tanaka and Jin-Sook So",
        },
        {
          image: require("@/assets/images/about/carousel/DailBehennah-KyokoKumai-MarietteRousseau-Vermette-TamikoKawata.jpg"),
          title:
            "works by Dail Behennah, Kyoko Kumai, Mariette Rousseau-Vermette, and Tamiko Kawata",
        },
        {
          image: require("@/assets/images/about/carousel/GyöngyLaky-ChangYeonsoon-KiyomiIwata.jpg"),
          title: "works by GyöngyLaky, ChangYeonsoon, and Kiyomi Iwata",
        },
        {
          image: require("@/assets/images/about/carousel/JinSookSo-Markku-Kosonen-ValeriePragnell-LiaCook-JiroYonezawa-KiyomiIwata-DebraSachs.jpg"),
          title:
            "works by Jin-Sook So, Markku Kosonen, Valerie Pragnell, Lia Cook, Jiro Yonezawa, Kiyomi Iwata, and Debra Sachs",
        },
        {
          image: require("@/assets/images/about/carousel/LenoreTawney-MagdalenaAbakanowicz-DEborahValoma-JiroYonezawa-MarietteRousseau-Vermette-DebraSachs.jpg"),
          title:
            "works by Lenore Tawney, Magdalena Abakanowicz, Deborah Valoma, Jiro Yonezawa, Mariette Rousseau-Vermette, and Debra Sachs",
        },
        {
          image: require("@/assets/images/about/carousel/NaokoSerino-MerjaWinqvist-KariLønning-Yasuhisa-Kohyama.jpg"),
          title: "works by Naoko Serino, Merja Winqvist, Kari Lønning, and Yasuhisa Kohyama",
        },
        {
          image: require("@/assets/images/about/carousel/StéphanieJacques-ScottRothstein.jpg"),
          title: "works by Stéphanie Jacques and Scott Rothstein",
        },
      ],
      slideActive: {},
    };
  },
  head: {
    title: function () {
      return {
        inner: "Our Story browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Our Story browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${
            process.env.VUE_APP_FRONTEND_URL
          }${require("@/assets/images/about/about_background.png")}`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Our Story browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${
            process.env.VUE_APP_FRONTEND_URL
          }${require("@/assets/images/about/about_background.png")}`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Our Story browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: `A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.`,
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Our Story browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: `A leading contemporary art gallery specializing in international art textiles, fiber sculpture, ceramics, and mixed media.`,
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${
            process.env.VUE_APP_FRONTEND_URL
          }${require("@/assets/images/about/about_background.png")}`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Our Story browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  computed: {
    ...mapGetters("publicapi/home", {
      featuredExhibition: "getFeaturedExhibition",
    }),
  },
  async created() {
    this.slideActive = this.carouselData[0];
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "About",
        to: "/about",
        clickable: false,
      },
    ];
    await this.apiGetFeaturedExhibition();
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicapi/home", ["apiGetFeaturedExhibition"]),
    goToSlide(moveDirection) {
      let carousel = this.$refs.carousel;

      switch (moveDirection) {
        case "prev": {
          carousel.prev();
          break;
        }
        case "next": {
          carousel.next();
          break;
        }
      }
    },
    changeSlide(slideIndex) {
      this.slideActive = this.carouselData[slideIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.8rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.about {
  .title {
    font-size: 4rem;
    line-height: 5rem;
    margin-bottom: 2rem;
    padding: 0rem 31.5rem;
    @media screen and (max-width: $lg) {
      padding: 0rem 18rem;
    }
    @media screen and (max-width: $xs) {
      font-size: 3.5rem;
      line-height: 4.3rem;
      margin-bottom: 3rem;
      padding: 0rem 1.9rem;
    }
  }

  .text {
    display: flex;
    padding: 0rem 31.5rem;
    @media screen and (max-width: $lg) {
      padding: 0rem 18rem;
    }
    @media screen and (max-width: $xs) {
      padding: 0rem 1.9rem;
    }

    &.mobile {
      display: none;
      margin-bottom: 2.6rem;
      @media screen and (max-width: $xs) {
        display: block;
      }
    }
  }

  .section-image {
    width: 100%;
    margin: 9.5rem 0rem 7.3rem;
    @media screen and (max-width: $xs) {
      margin: 7rem 0rem;
    }
  }

  section {
    z-index: 3;
  }

  &__header {
    position: relative;
    width: 100%;
    @media screen and (max-width: $xs) {
      height: 24rem;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 101%;
      background: linear-gradient(0deg, rgb(0, 0, 0) 15%, rgba(0, 0, 0, 0) 100%);
      // height: 100vh;
      // background: linear-gradient(0deg, rgb(0, 0, 0) 35%, rgba(0, 0, 0, 0) 100%);
      z-index: 2;
      @media screen and (max-width: $xs) {
        height: 42vh;
      }
    }

    &_background {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__story {
    position: relative;
    margin-bottom: 5.5rem;
    top: -13.7rem;
    @media screen and (max-width: $lg) {
      top: -11rem;
    }
    @media screen and (max-width: $xs) {
      top: 0;
    }
  }

  &__about-us {
    padding-bottom: 11rem;
    @media screen and (max-width: $xs) {
      padding-bottom: 7rem;
    }
    &__slide-description {
      font-weight: 100;
      margin: 0 auto;
      max-width: 106rem;
      text-align: center;
      @media screen and (max-width: $xs) {
        padding: 0 25px;
      }
    }
    .carousel {
      &-wrapper {
        position: relative;
        margin-top: 11rem;
        width: 100%;
        @media screen and (max-width: $xs) {
          margin-top: 7rem;
        }
        // @media screen and (max-width: $xs) {
        //   height: 344px;
        // }

        .slide {
          padding: 0px 5px;
          @media screen and (max-width: $xs) {
            padding: 0;
          }

          .image {
            height: 600px;
            width: auto;
            background-color: $light4Grey;
            object-fit: cover;
            @media screen and (max-width: $xs) {
              width: 100vw;
              height: auto;
            }
          }
        }
      }

      &__button {
        position: absolute;
        width: 7rem;
        height: 7rem;
        top: calc(50% - 3.5rem);
        z-index: 1;
        @media screen and (max-width: $xs) {
          width: 4.2rem;
          height: 4.2rem;
          top: calc(50% - 2.1rem);
        }
        &--next {
          right: 10.5rem;
          transform: rotate(90deg) translateY(-50%);
          @media screen and (max-width: $xs) {
            right: 4rem;
          }
        }
        &--back {
          left: 10.5rem;
          transform: rotate(-90deg) translateY(-50%);
          @media screen and (max-width: $xs) {
            left: 4rem;
          }
        }
        &-icon {
          width: 18px;
          height: 12px;
          @media screen and (max-width: $xs) {
            width: 10px;
            height: 6px;
          }
        }
      }
    }
  }

  // &__contacts {
  //   padding: 0rem 31.5rem;
  //   margin-bottom: 15.2rem;
  //   @media screen and (max-width: $lg) {
  //     padding: 0rem 18rem;
  //   }
  //   @media screen and (max-width: $xs) {
  //     padding: 0rem 1.9rem;
  //     margin-bottom: 10rem;
  //   }
  // }

  // &__sign-up {
  //   padding: 0rem 60rem;
  //   margin-bottom: 22.8rem;
  //   @media screen and (max-width: $lg) {
  //     padding: 0rem 18rem;
  //   }
  //   @media screen and (max-width: $xs) {
  //     padding: 0rem 1.9rem;
  //     margin-bottom: 24rem;
  //   }
  // }
}
</style>
